import { ErrorMessage } from '@hookform/error-message';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, Footer, Logo, Profile, HistoryLogs, Message } from '../../components'
import { AppContext } from '../../contexts';
import { useApiService, useContextService } from '../../services';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask'
import { PageUrl } from '../../util/app-config';
import moment from "moment";
import { InternetUserType, RoleType } from '../../types/enum/user-type';
import './company.css'

function Company() {

    const appContext = useContext(AppContext);
    const internetUser = appContext.internetUser;
    const navigate = useNavigate();
    const { getRegistrationOnEnquiry, getRegistrationOnEnquiryMeat } = useApiService();
    const { putContextRegistration, putContextRegistrationMeat, patchContextRegistration } = useContextService();
    const [isFoodHandlerNotFound, setIsFoodHandlerNotFound] = useState<boolean>(false);
    const [isFoodHandler, setFoodHandler] = useState<boolean>(true);
    const [isMessage, setMessage] = useState('');
    const { register, handleSubmit, control, getValues, reset, formState: { errors } } = useForm();

    const onClickSearch = async () => {

        console.log("Registration Start - WOGAA");
        if (window.wogaaCustom) {
            window.wogaaCustom.startTransactionalService('fhd2hubsfa-5327');
        }

        handleSubmit(async (data) => {
            if (isFoodHandler) {
                const result = await getRegistrationOnEnquiry(data.uinPassport, data.dob);
                if (result) {
                    /*  await putContextRegistration(result);*/
                    await putContextRegistration(result);
                    navigate(PageUrl.EnquiryResult)
                }
                else {
                    if (internetUser.type === InternetUserType.TrainingProvider)
                    {
                        const resultUIN = await getRegistrationOnEnquiry(data.uinPassport);
                        if (resultUIN) {
                            setMessage('Invalid food handler!');
                            setIsFoodHandlerNotFound(true);
                        }
                        else {
                            //clear the previous image when UIN not found
                            appContext.image = {};
                            await patchContextRegistration(data.uinPassport, data.dob);
                            navigate(PageUrl.EnquiryNotFound)
                        }
                    }
                    else {
                        setMessage('Invalid food handler!');
                        setIsFoodHandlerNotFound(true);
                    }
                }
            }
            else {

                const result = await getRegistrationOnEnquiryMeat(data.uinPassport, data.dob);
                if (result)
                {
                   // await putContextRegistrationMeat(result);
                    await putContextRegistrationMeat(result);
                    navigate(PageUrl.EnquiryMeatResult)
                }
                else
                {
                    if (internetUser.type === InternetUserType.TrainingProvider)
                    {
                        const resultUIN = await getRegistrationOnEnquiryMeat(data.uinPassport);
                        if (resultUIN) {
                            setMessage('Invalid meat handler!');
                            setIsFoodHandlerNotFound(true);
                        }
                        else
                        {
                            //clear the previous image when UIN not found
                            appContext.image = {};
                            await patchContextRegistration(data.uinPassport, data.dob);
                            navigate(PageUrl.EnquiryMeatNotFound)
                        }
                    }
                    else {
                        setMessage('Invalid meat handler!');
                        setIsFoodHandlerNotFound(true);
                    }
                }
            }

            console.log("Registration End - WOGAA");
            if (window.wogaaCustom) {
                window.wogaaCustom.completeTransactionalService('fhd2hubsfa-5327');
            }
        })()
    }

    const handleChange = async (e: any) => {
        const target = e.target;
        if (target.Id == "radioFoodHandler")
            setFoodHandler(true);
        else
            setFoodHandler(false);
    }
     

    return (
        <div>
            <div className="gradient-box">
                <Header></Header>
                <Profile isVisibleProfile={true}></Profile>
                <div className="half_bg-img">
                    <div className="container">
                        <div className="white-box-curved enguiry-page">
                            <div className="enguiry-bx bg-trans">
                                {internetUser?.lastLogin && <span className="float-end">Last login:  {moment(internetUser.lastLogin).format('DD/MM/YYYY HH:mm')}</span>}
                                <h3><small>Hello!</small> {internetUser.name}</h3>
                            </div>
                            <div className="reg-form company-form">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="company-cnt">
                                            <figure>
                                                <img src="/ClientApp/build/Images/search-filter.png" alt="" className="img-fluid" />
                                            </figure>
                                            <h4>Search for Food Handler / Meat Handler</h4>
                                            <p>Type the UIN OR Passport No. to check if a person is a verified <strong>FOOD HANDLER / MEAT HANDLER</strong> </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="company-cnt-form">
                                            <form>
                                                <div className="row action-radio">
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <input type="radio" id="radioFoodHandler" checked name="radio-group" onChange={handleChange} />
                                                            <label htmlFor="radioFoodHandler">Food Handler</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="form-group">
                                                            <input type="radio" id="radioMeatHandler" name="radio-group" onChange={handleChange} />
                                                            <label htmlFor="radioMeatHandler">Meat Handler</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br></br>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Please enter the UIN or Passport No.</label>
                                                    <input type="text" className="form-control" placeholder="UIN / Passport No." id="uinPassport"
                                                        {...register('uinPassport', { required: 'UIN or Passport No is required' })} />
                                                    <ErrorMessage errors={errors} name="uinPassport" render={({ message }) => <p className="errorMsg">{message}</p>} />

                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Date of Birth</label>
                                                    <Controller
                                                        control={control}
                                                        {...register('dob', { required: 'Date of Birth is required' })}
                                                        name='dob'
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                placeholderText='DD / MM / YYYY'
                                                                onChange={(date: any) => field.onChange(date)}
                                                                selected={field.value}
                                                                customInput={<MaskedInput className='form-control'
                                                                    mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                />}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        )} />
                                                    <ErrorMessage errors={errors} name="dob" render={({ message }) => <p className="errorMsg">{message}</p>} />
                                                </div>
                                                <p><span className="star-red">*</span> Use the same identification number that was used to register for the Food Handler Training Course (UIN / Passport No.)</p>
                                            </form>
                                            <div className="text-center">
                                                {isFoodHandlerNotFound && getValues("uinPassport") !== '' && <p><span className="errorMsg msg-bold">{isMessage}</span></p>}
                                                <button onClick={onClickSearch} className="secondary-btn mt-3 me-0">Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <HistoryLogs logs={internetUser.logs}></HistoryLogs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    );
}
export default Company;