import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Header, Profile } from "../../components";
import { AppContext } from "../../contexts";
import { useApiService, useContextService } from "../../services";
import { FoodHandlerStatus } from "../../types/enum/foodhandler-status";
import { InternetUserType } from "../../types/enum/user-type";
import { PageUrl } from "../../util/app-config";
import './enquiry.css'

function EnquiryResult() {
  const appContext = useContext(AppContext);
    const registration = appContext.registration;
  const imageContext = appContext.image;
  const navigationContext = appContext.navigation;
  const internetUser = appContext.internetUser;
  const [isPhotoUploaded, setIsPhotoUploaded] = useState<boolean>(false)
  const [enquireBtnText] = useState<string>('Search Another Food Handler')
  const [warningMessage, setWarningMessage] = useState<string>('')
  const [pageTitle] = useState<string>('FOOD HANDLER VERIFICATION ENQUIRY')
  const { updateRegistration, saveFile } = useApiService();
  const { patchContextRegistrationAttachment } = useContextService();
  const navigate = useNavigate();

  const onClickEnquireAgain = () => {
    if (internetUser === null) {
      navigate(PageUrl.Enquiry)
    }
    else if (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider) {
      navigate(PageUrl.Company)
    } else {
      navigate(PageUrl.Enquiry)
    }
  }

    const onClickTakePhoto = () => {
     
    navigationContext.fromPage = PageUrl.EnquiryResult
    navigate(PageUrl.Camera)
  }

const onClickUpdatePhoto = async () => {

    if (imageContext.imageBlob) {

        console.log("Registration Start - WOGAA");
        if (window.wogaaCustom) {
            window.wogaaCustom.startTransactionalService('fhd2hubsfa-5327');
        }

        const file = await saveFile(imageContext.imageBlob, registration.uinPassport)
        if (file !== null) {
            await patchContextRegistrationAttachment(file);
            await updateRegistration(registration);
            setIsPhotoUploaded(true)
        }

        console.log("Registration End - WOGAA");
        if (window.wogaaCustom) {
            window.wogaaCustom.completeTransactionalService('fhd2hubsfa-5327');
        }
    }
}

  const onClickBack = () => {
    navigate(PageUrl.Home)
  }

  const visibleProfile = () => {
    return (internetUser !== undefined && internetUser !== null) && (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider);
  }

  const isUserAllowToTakePhoto = () => {
    return registration.status === FoodHandlerStatus.Active && internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider;
  }

  useEffect(() => {
    if ((internetUser !== undefined && internetUser !== null) && (internetUser?.type === InternetUserType.BusinessOwner || internetUser?.type === InternetUserType.TrainingProvider)) {
      if (!imageContext.imageBase64 && registration.status === FoodHandlerStatus.Active) {
        setWarningMessage('Food handler is missing a photo. please take a photo now')
      }
    }
  }, []);

    return (
        <div>
            <div className="gradient-box">
                <Header></Header>

                <Profile isVisibleProfile={visibleProfile()}></Profile>
                <div className="half_bg-img">
                    <div className="container">
                        <div className="white-box-curved enguiry-page">
                            <div className="enguiry-bx">
                                {internetUser?.lastLogin !== undefined && <span className="float-end">Last login:  {moment(internetUser.lastLogin).format('DD/MM/YYYY HH:mm')}</span>}
                                <h3>{pageTitle}</h3>
                            </div>
                            {warningMessage.length > 0 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <br /> <p className="photo-required-message"><i className="fas fa-exclamation-triangle"></i> {warningMessage}</p>
                                    </div>
                                </div>
                            }
                            <div className="enguiry-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        <figure>
                                            {imageContext.imageBase64 ?
                                                <img src={imageContext.imageBase64} width="100" height="100" alt="" className="img-fluid" />
                                                :
                                                <img src="/ClientApp/build/Images/user_profile_icon.png" alt="" className="img-fluid" />
                                            }
                                        </figure>
                                        <span>Full Name</span>
                                        <h4>{registration.name} </h4>
                                    </div>

                                    <div className="col-md-3">
                                        <span>UIN</span>
                                        <h4>{registration.uinPassport}</h4>
                                    </div>
                                    <div className="col-md-3">
                                        <span>Date of Birth</span>
                                        <h4>{(moment(registration.dob)).format('DD-MMM-YYYY').toLocaleString()}</h4>
                                    </div>
                                </div>
                                <h5><strong>Is this person a registered FOOD HANDLER?</strong>
                                    {(() => {
                                        switch (registration.status) {
                                            case 100: return <span className="bg-red">{registration.statusText}</span>;
                                            case 200: return <span className="bg-green">{registration.statusText}</span>;
                                            case 300: return <span className="bg-red">{registration.statusText}</span>;
                                            case 400: return <span className="bg-red">{registration.statusText}</span>;
                                            case 500: return <span className="bg-red">{registration.statusText}</span>;
                                            case 600: return <span className="bg-red">{registration.statusText}</span>;
                                            default:
                                                return <span className="bg-red">Error</span>;
                                        }
                                    })()}
                                    {/*{registration.status !== FoodHandlerStatus.Active ? <span className="bg-red">NO</span> : <span className="bg-green">YES</span>}*/}
                                </h5>
                            </div>
                            <div className="col-md-12">
                                {internetUser === null && <button className="secondary-btn" onClick={onClickBack}>Go to Main Page</button>}
                                {registration.status === FoodHandlerStatus.Active && isUserAllowToTakePhoto() && !imageContext.imageBase64 && <button className="secondary-btn" onClick={onClickTakePhoto}>Take Photo</button>}
                                {registration.status === FoodHandlerStatus.Active && isUserAllowToTakePhoto() && imageContext.imageBlob && !isPhotoUploaded && <button className="secondary-btn" onClick={onClickUpdatePhoto}>Update Photo</button>}
                                <button className="primary-btn" onClick={onClickEnquireAgain}>{enquireBtnText}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default EnquiryResult;